<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card card-shadow">
          <div class="container-fluid">
            <div class="row">
              <div class="col text-left">
                <div class="card-body">
                  <h5 class="card-title">Messages</h5>
                  <p class="card-text"></p>
                </div>
              </div>
              <div class="col-xs-12 col-md-4 text-center">
                <div class="card-body input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search for messages..."
                    v-model="search"
                    @keyup.enter="searchMessages"
                  />
                  <div class="input-group-append">
                    <b-button variant="outline-primary" @click="searchMessages">
                      <i class="fas fa-search"></i> Search
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-4 text-right">
                <div class="card-body">
                  <b-form-checkbox v-model="showOpened" switch>
                    <div class="message-switch-label text-left">
                      {{ messagesShown }}
                    </div>
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="alert alert-primary" role="alert" v-if="!showSearch">
            <i class="fas fa-info-circle"></i> You have {{ unreadCount }} unread
            messages and {{ totalCount }} total messages.
          </div>
          <div class="alert alert-warning" role="alert" v-if="showSearch">
            <i class="fas fa-info-circle"></i> Results: {{ resultCount }}
            <b-button
              variant="outline-primary"
              size="sm"
              class="float-right"
              @click="clearSearch"
              ><i class="fas fa-backspace"></i> Clear</b-button
            >
          </div>
          <div class="table-responsive">
            <table class="table table-hover table-striped messages-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>From</th>
                  <th>Subject</th>
                  <th></th>
                </tr>
              </thead>
              <tbody id="message-list">
                <tr
                  class="message-row clickable"
                  v-for="(message, index) in selectedMessages"
                  :key="index"
                >
                  <td :class="message.opened ? openedClass : ''">
                    {{ formatDate(message.created) }}
                  </td>
                  <td :class="message.opened ? openedClass : ''">
                    {{ message.sender }}
                  </td>
                  <td :class="message.opened ? openedClass : ''">
                    {{ message.subject }}
                  </td>
                  <td class="text-right">
                    <b-button
                      variant="outline-primary"
                      class="mr-2"
                      :to="{
                        name: 'read-message',
                        params: { id: message['.key'] }
                      }"
                    >
                      <i class="far fa-file-alt"></i> Read
                    </b-button>
                    <b-button
                      variant="outline-danger"
                      v-b-modal="'modal-delete-' + message['.key']"
                    >
                      <i class="far fa-trash-alt"></i> Delete
                    </b-button>

                    <b-modal
                      :id="'modal-delete-' + message['.key']"
                      title="Delete Message"
                      header-bg-variant="danger"
                      header-text-variant="light"
                      ok-variant="danger"
                      ok-title="Delete"
                      @ok="deleteMessage(message['.key'])"
                    >
                      <p class="my-4">
                        Are you sure you want to delete the message
                        <b>{{ message.subject }}</b
                        >? This cannot be undone.
                      </p>
                    </b-modal>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
//import firebase from "firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import moment from "moment";

export default {
  name: "Messages",
  mixins: [FirebaseMixin, UtilsMixin],
  data() {
    return {
      ref: "messages",
      messages: [],
      openedClass: "opened-row",
      showOpened: true,
      search: "",
      searchText: "",
      showSearch: false
    };
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        if (uid) {
          this.bindObject(this.ref + "/" + uid, null, "messages");
        }
      }
    }
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    userUid() {
      return this.userProfile.uid;
    },
    unreadCount() {
      let count = 0;
      this.messages.forEach(message => {
        if (!message.opened) {
          count++;
        }
      });
      return count;
    },
    totalCount() {
      return this.messages.length;
    },
    messagesShown() {
      return this.showOpened ? "All Messages" : "Open Messages";
    },
    unreadMessages() {
      return this.messages.filter(message => {
        return message.opened === false;
      });
    },
    searchResults() {
      return this.messages.filter(message => {
        return (
          message.sender
            .toLowerCase()
            .includes(this.searchText.toLowerCase()) ||
          message.subject
            .toLowerCase()
            .includes(this.searchText.toLowerCase()) ||
          message.text.toLowerCase().includes(this.searchText.toLowerCase())
        );
      });
    },
    resultCount() {
      return this.searchResults.length;
    },
    selectedMessages() {
      if (this.showSearch) {
        return this.searchResults;
      } else if (this.showOpened) {
        return this.messages;
      } else {
        return this.unreadMessages;
      }
    }
  },
  methods: {
    ...mapMutations(["setUser", "setProfile"]),
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY hh:mm:ss A");
    },
    searchMessages() {
      this.searchText = this.search;
      this.showSearch = true;
    },
    clearSearch() {
      this.showSearch = false;
    },
    deleteMessage(key) {
      this.deleteObject(this.ref + "/" + this.userUid, key)
        .then(() => {
          window.toastr.success("Message deleted successfully.");
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred while deleting the message. Please try again later."
          );
        });
    }
  },
  created() {}
};
</script>

<style scoped>
.alert {
  border-radius: 0;
}

.opened-row {
  color: #6c757d !important;
  font-style: italic;
}

#message-reader {
  max-width: 600px;
}

.message-card {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.message-switch-label {
  width: 115px;
}
</style>
