<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card shadow-sm message-card">
          <div class="container-fluid">
            <div class="row">
              <div class="col text-left">
                <div class="card-body">
                  <h5 class="card-title">Read Message</h5>
                  <p class="card-text"></p>
                </div>
              </div>
              <div class="col-xs-12 col-md-4 text-right">
                <div class="card-body"></div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col card-body">
                <div class="form-group">
                  <label for="inpDate">Date</label>
                  <input
                    name="date"
                    type="text"
                    class="form-control"
                    id="inpDate"
                    placeholder="Date"
                    :value="formatDate(message.created)"
                    readonly
                  />
                </div>
                <div class="form-group">
                  <label for="inpSender">Sender</label>
                  <input
                    name="sender"
                    type="text"
                    class="form-control"
                    id="inpSender"
                    placeholder="Sender"
                    :value="message.sender"
                    readonly
                  />
                </div>
                <div class="form-group">
                  <label for="inpSubject">Subject</label>
                  <input
                    name="subject"
                    type="text"
                    class="form-control"
                    id="inpSubject"
                    placeholder="Subject"
                    :value="message.subject"
                    readonly
                  />
                </div>
                <div class="form-group">
                  <label for="message-reader">Message</label>
                  <b-form-textarea
                    id="message-reader"
                    v-model="message.text"
                    placeholder="Message..."
                    rows="8"
                    max-rows="8"
                    no-resize
                    readonly
                  ></b-form-textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-left">
                <div class="card-body">
                  <b-button
                    variant="outline-primary"
                    :to="{ name: 'messages' }"
                    class="mr-2"
                  >
                    <i class="far fa-arrow-alt-circle-left"></i> Back</b-button
                  >
                  <b-button variant="outline-danger" v-b-modal="'modal-delete'">
                    <i class="far fa-trash-alt"></i> Delete
                  </b-button>

                  <b-modal
                    id="modal-delete"
                    title="Delete Message"
                    header-bg-variant="danger"
                    header-text-variant="light"
                    ok-variant="danger"
                    ok-title="Delete"
                    @ok="deleteMessage(message['.key'])"
                  >
                    <p class="my-4">
                      Are you sure you want to delete message
                      <b>{{ message.subject }}</b
                      >? This cannot be undone.
                    </p>
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
//import firebase from "firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import moment from "moment";

export default {
  name: "ReadMessage",
  mixins: [FirebaseMixin, UtilsMixin],
  data() {
    return {
      ref: "messages",
      refkey: null,
      message: {}
    };
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        if (uid && this.refkey) {
          this.bindObject(this.ref + "/" + uid, this.refkey, "message");
        }
      }
    },
    refkey: {
      immediate: true,
      handler(key) {
        if (key && this.userUid) {
          this.bindObject(this.ref + "/" + this.userUid, key, "message");
        }
      }
    }
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    userUid() {
      return this.userProfile.uid;
    }
  },
  methods: {
    ...mapMutations(["setUser", "setProfile"]),
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY hh:mm:ss A");
    },
    deleteMessage(key) {
      this.deleteObject(this.ref + "/" + this.userUid, key)
        .then(() => {
          window.toastr.success("Message deleted successfully.");
          this.navigate("messages");
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred while deleting the message. Please try again later."
          );
        });
    }
  },
  created() {
    this.refkey = this.$route.params.id;
  }
};
</script>

<style scoped>
.message-card {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
</style>
